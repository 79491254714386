<template>
  <div>
    <form-wizard
      color="#9600ff"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="vertical-steps steps-transparent mb-3 meeting-steps-design"
      ref="wizard"
    >
          <!-- Meetings -->
     
      <!-- Meeting General Informations -->
      <tab-content  title="General Informations" icon="feather icon-video">
        <Meetinggeneral :Meetingselected="Meetingselected"/>
      </tab-content>

      <!-- Meeting invites -->
      <tab-content  title="Meeting invites" icon="feather icon-users">
        <Meetinginvites />
      </tab-content>

      <!-- Meeting Advanced settings-->
      <tab-content  title="Meeting advanced" icon="feather icon-settings">
        <Meetingadvanced :selected="Meetingselected"/>
      </tab-content>
      
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Meetinggeneral from "./general/Meeting-general.vue"
import Meetinginvites from "./invites/Meeting-invites.vue"
import Meetingadvanced from "./advanced/Meeting-advanced.vue"
import MyMeetings from "./mymeeting/Mymeetings.vue"
import axios from "axios";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    // Meeting components
    Meetinggeneral,
    Meetinginvites,
    Meetingadvanced,
    MyMeetings
  },
  data() {
    return {
      active:true,
      checked:true,
      Meetingselected:{}
    }
  },

  computed:{
      // Meetingselected(){
      //   const meet = {}
      //   axios
      //   .get("MEETINGS-API with id query")
      //   .then((response) => {
      //     return response
      //   })
      //   .catch((error) => {
      //     this.errorMessage = error.message;
      //   });
      // },
    },
  methods: {

      },
      mounted(){
        this.$refs.wizard.maxStep = 3
        this.$refs.wizard.navigateToTab(0)   

          axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/meetings?id="+this.$route.params.id
        )
        .then((response) => {
          this.Meetingselected=response.data.data
        })
        .catch((error) => {
          this.errorMessage = error.message;
          });
       },


    }
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
[dir] .steps-transparent .wizard-tab-content {
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
[dir="ltr"] .steps-transparent .wizard-card-footer {
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
[dir] .vue-form-wizard .wizard-tab-content {
  padding: 10px 1px 15px !important;
}
// .vue-form-wizard.vertical .wizard-card-footer{
// display: none!important;
// }
</style>
