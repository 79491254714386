<template>
  <div class="mettings">
    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <b-card-group>
            <b-card align="center">
              <!-- SEARCH BAR  -->
              <!-- <div
              style="padding-bottom: 6px;"
                class="
                  d-flex
                  align-content-center
                  justify-content-between
                  w-100
                "
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-prepend>
                  <b-form-input
                    :value="searchQuery"
                    placeholder="Search Invite"
        
                  />
                </b-input-group>
              </div> -->
            <h4 class="color-primary">{{ $t('Pending_invitation') }}</h4>
            <b-row>
              <b-col cols="md-12 sm-12 mt-2" v-if="pendingInvitation.length === 0">
                <h5 class="text-center">{{ $t('No_invitation') }}</h5>
                </b-col>
                <b-col
                  class="invites-cards"
                  cols="lg-6 md-4 sm-12"
                  v-for="(invite, index) in pendingInvitation"
                  :key="index"
                >
                  <b-card
                    style="background: #5c00f10d"
                    :header="'Invite from ' + invite.invitationSender.name"
                  >
                    <b-card-text>
                      <b-badge class="invite-badge" href="#" variant="info">{{
                        dateFormat(invite.selectedDate,invite.time,invite.duration)
                      }}</b-badge>
                      <div class="description-adjust" style="padding-top: 18px">
                        <p class="invite-description">
                          {{ invite.invitationSender.email }}
                        </p>
                        <p class="invite-description cut-text" v-if="invite.invitationSender.description">
                          {{ minimizedDescription(invite.invitationSender.description) }}
                        </p>
<p class="invite-description" v-else>{{ $t('No_description_available') }}</p>
</div>
</b-card-text>
<b-row class="button-invites">
  <b-col col lg="4" md="4" sm="12">
    <b-button class="invite-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-success"
      @click="acceptMeeting(invite,'pending')"
    >
      {{ $t('Accept') }}
    </b-button>
  </b-col>
  <b-col col lg="4" md="4" sm="12">
    <b-button class="invite-btn"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-danger"
      @click="declineMeeting(invite,'pending')"
    >
      {{ $t('Decline') }}
    </b-button>
  </b-col>
  <b-col col lg="4" md="4" sm="12">
    <b-button class="btn btn-md btn-danger"
      @click="deleteInvite(invite._id)"
    >
      {{ $t('Delete') }}
    </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col> </b-col>
              </b-row>
              <hr>
             <!-- Accepted invitations -->
<h4 class="color-primary">{{ $t('Accepted_invitation') }}</h4>
<b-col cols="md-12 sm-12 mt-2" v-if="acceptedInvitation.length === 0">
  <h5 class="text-center">{{ $t('No_invitation_for_the_moment') }}</h5>
</b-col>
<b-row>
  <b-col
    cols="lg-6 md-4 sm-12"
    class="invites-cards"
    v-for="(invite, index) in acceptedInvitation"
    :key="index"
  >
    <b-card
      style="background: #5c00f10d"
      :header="$t('Invite_from') + ' ' + invite.invitationSender.name"
    >
      <b-card-text>
        <b-badge class="invite-badge" href="#" variant="info">{{
          dateFormat(invite.selectedDate, invite.time, invite.duration)
        }}</b-badge>
        <div class="description-adjust" style="padding-top: 18px">
          <p class="invite-description">
            {{ invite.invitationSender.email }}
          </p>
          <p class="invite-description cut-text" v-if="invite.invitationSender.description">
            {{ minimizedDescription(invite.invitationSender.description) }}
          </p>
          <p class="invite-description" v-else>{{ $t('No_description_available') }}</p>
        </div>
      </b-card-text>
      <b-row class="button-invites">
        <b-col cols="6">
          <b-button class="invite-btn"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            @click="declineMeeting(invite, 'delete')"
          >
            {{ $t('Decline') }}
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button class="btn btn-md btn-danger"
            @click="deleteInvite(invite._id)"
          >
            {{ $t('Delete') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
  <b-col></b-col>
</b-row>
<hr>

<!-- Refused invitations -->
<h4 class="color-primary">{{ $t('Refused_invitation') }}</h4>
<b-col cols="md-12 sm-12 mt-2" v-if="refusedInvitation.length === 0">
  <h5 class="text-center">{{ $t('No_invitation_for_the_moment') }}</h5>
</b-col>
<b-row>
  <b-col
    cols="lg-6 md-4 sm-12"
    class="invites-cards"
    v-for="(invite, index) in refusedInvitation"
    :key="index"
  >
    <b-card
      style="background: #5c00f10d"
      :header="$t('Invite_from') + ' ' + invite.invitationSender.name"
    >
      <b-card-text>
        <b-badge class="invite-badge" href="#" variant="info">{{
          dateFormat(invite.selectedDate, invite.time, invite.duration)
        }}</b-badge>
        <div class="description-adjust" style="padding-top: 18px">
          <p class="invite-description">
            {{ invite.invitationSender.email }}
          </p>
          <p class="invite-description cut-text" v-if="invite.invitationSender.description">
            {{ minimizedDescription(invite.invitationSender.description) }}
          </p>
          <p class="invite-description" v-else>{{ $t('No_description_available') }}</p>
        </div>
      </b-card-text>
      <b-row class="button-invites">
        <b-col cols="6">
          <b-button class="invite-btn"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            @click="acceptMeeting(invite, 'accept')"
          >
            {{ $t('Accept') }}
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button class="btn btn-md btn-danger"
            @click="deleteInvite(invite._id)"
          >
            {{ $t('Delete') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-col>

                <b-col> </b-col>
              </b-row>
            </b-card> </b-card-group
        ></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from 'moment';

import {
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BCard,
  BCardText,
  BCardGroup,
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCardFooter,
  BBadge,
} from "bootstrap-vue";
import axios from "axios";

export default {
  data() {
    return {
      invitationsMeet:[],
      acceptedInvitation:[],
      refusedInvitation:[],
      pendingInvitation:[],
      selectedmeeting: null,
    };
  },

  components: {
    ToastificationContent,
    BCardActions,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BCardText,
    BCardGroup,
    BRow,
    BCol,
    BContainer,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,
    BInputGroupPrepend,
    BInputGroupAppend,
    BBadge,
  },
  directives: {
    Ripple,
  },
  created() {
    this.getInvitations()
  },
  computed: {},
  methods: {
    minimizedDescription(description) {
      const maxLength = 90;
      if (description.length <= maxLength) {
        return description;
      } else {
        return description.slice(0, maxLength) + "...";
      }
    },
    dateFormat: function (date,time,duration) {
      const [hours, minutes] = time.split(":");
      const hour = parseInt(hours, 10);
      let period = "AM";
      if (hour >= 12) {
        period = "PM";
      }
      const times = time + " " + period
      return moment(date).format('YYYY/MM/DD') + " , " + times + " , " + duration + " min";
    },
    async getInvitations (){
      this.pendingInvitation = []
      this.refusedInvitation = []
      this.acceptedInvitation = []
        await axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/meetingInvitation?meeting="+this.$route.params.id
        )
        .then((response) => {
          this.invitationsMeet=response.data.data
          this.invitationsMeet.forEach(element => {
            if (element.status==='pending'){
              this.pendingInvitation.push(element)
            } else if (element.status==='rejected') {
              this.refusedInvitation.push(element)
            } else if (element.status==='accepted') {
              this.acceptedInvitation.push(element)
            }
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },

    acceptMeeting(invite,type) {
      if(type =='pending'){
        var index = this.pendingInvitation.findIndex(function(o){
              return o.id == invite.id;
        })
        if (index !== -1) this.pendingInvitation.splice(index, 1); 
      }else{
         var index = this.refusedInvitation.findIndex(function(o){
              return o.id == invite.id;
        })
        if (index !== -1) this.refusedInvitation.splice(index, 1); 
      }     
        this.acceptedInvitation.push(invite)
        axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/meetingInvitation/"+invite._id+"/accept"
        )
        .then((response) => {
          this.invitationsMeet=response.data.data
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "meetings invitation accepted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
            this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error during update",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          });

    },

    declineMeeting(invite,type){
        if(type =='pending'){
        var index = this.pendingInvitation.findIndex(function(o){
              return o.id == invite.id;
        })
        if (index !== -1) this.pendingInvitation.splice(index, 1);
      }else{
         var index = this.acceptedInvitation.findIndex(function(o){
              return o.id == invite.id;
        })
        if (index !== -1) this.acceptedInvitation.splice(index, 1);
      }
      this.refusedInvitation.push(invite)

       axios
        .put(
          "https://backendapinodejs.timecheckit.com/api/meetingInvitation/"+invite._id+"/reject"
        )
        .then((response) => {
          this.invitationsMeet=response.data.data
               this.$toast({
            component: ToastificationContent,
            props: {
              title: "meetings invitation declined",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
                this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error during update",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          });
    },
    deleteInvite(id) {
      this.$swal({
        title: "Are you sure you want to delete this invite permanently?",
        text: "You can't undo this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm !",
        cancelButtonText: "Decline !",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.delete(`https://backendapinodejs.timecheckit.com/api/meetingInvitation/${id}`)
          .then((response) => {
            this.getInvitations()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Invite deleted`,
                icon: "UserIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error while deleting`,
                  icon: "UserIcon",
                  variant: "danger",
                },
            });
          });
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// scoped

.mettings .card-body {
  padding-top: 3% !important;
}
@media screen and (max-width: 768px) {
  .mettings .card-text{
    width: 100%;
  }
  .mettings .card-text .badge{
    width: 100%;
    white-space: normal;
  }
  .mettings .button-invites .btn{
    width: 100%;
    margin-bottom: 5%!important;
  }
}


@media (min-width: 576px) {
  [dir="ltr"] .card-deck {
  margin-right: 4rem;
  margin-left: 4rem;
}
// Right content area common css starts
.content-right {
  width: calc(100% - #{$menu-expanded-width});
  border-left: 1px solid $border-color;
  .todo-app-list {
    height: inherit;
  }
  // Todo Fixed search
  .app-fixed-search {
    padding: 0.35rem 0.5rem;
    border-bottom: 1px solid $border-color;
    background-color: $white;
    border-top-right-radius: $border-radius;
    .input-group:focus-within {
      box-shadow: none;
    }
    input,
    .input-group-text {
      border: 0;
      background-color: transparent;
    }
  }
}
.invites-cards {
  padding: 1rem;
}
.button-invites {
  margin-top: 25px;
}
/*.invite-description {
      padding: 0.2rem 0.1rem;
}*/
.received-time {
  font-size: 0.75em;
  padding: 0.5rem 0.9rem;
}
/*.description-adjust {
  width: 100%;
  height: 6vw;
  -o-object-fit: cover;
  object-fit: cover;
}*/
/*.invite-btn{
  padding: 0.8rem 0.8rem;
}*/
[dir] .card .card-header {
    border-bottom: none;
    padding: 1.5rem;
    // background-color: #756af0 !important;
    color: white !important;
}
}
.invite-badge {
  margin-top: 5px;
  padding: 10px 18px;
  background-color: #756af0 !important;
}
.color-primary{
  color: #756af0!important;
}
/*.cut-text { 
  text-overflow: ellipsis;
  overflow: hidden; 
  height: 5em; 
  white-space: nowrap;
}*/
</style>
