<template>
  <div class="mettings">
    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <b-card-group >
            <b-card header="Schedule a meeting" align="left">
            <!-- <b-card header="Schedule a meeting" align="left" @submit.prevent="handleSubmit"> -->
              <b-row style="top: 0px;">
                <b-col cols="md-6 sm-12">
                  <h2>{{ $t('Meeting_title') }}</h2>
                  <b-form-group label-for="basicInput">
                    <b-form-input id="basicInput" v-model="meeting.meetingName" placeholder="Meeting title" />
                  </b-form-group>
                </b-col>
                <b-col cols="md-6 sm-12">
                  <h2>{{ $t('Meeting_duration') }}</h2>
                  <!-- time Date -->
                  <b-form-group label-for="Time reminder">
                    <v-select
                    multiple
                      v-model="meeting.duration"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="durations"
                      label="title"
                      class="assignee-selector"
                      :placeholder="$t('Select_meeting_duration')"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" style="margin-bottom: 2%">
                  <h2>{{ $t('Meeting_description') }}</h2>
                  <b-form-textarea
                    id="textarea-default"
                    v-model="meeting.description"
                    :placeholder="$t('Meeting_description_placeholder')"
                    rows="3"
                  />
                </b-col>
                <b-col md="6">
                  <b-form-group label="" label-for="start">
                    <h5>{{ $t('Start_date') }}</h5>
                    <b-form-datepicker
                        placeholder="Date"
                        v-model="meeting.startDate"
                        id="start"
                        :max="maxEndDate"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" class="mb-2">
                  <b-form-group label="" label-for="end">
                    <h5>{{ $t('End_date') }}</h5>
                    <b-form-datepicker
                        placeholder="Date"
                        v-model="meeting.endDate"
                        id="end"
                        :min="minEndDate"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="working-hours" cols="md-8 sm-12">
                  <h2>{{ $t('Working_hours') }}</h2>
                  <p>
                        {{ $t('Working_hours_description') }}

                  </p>
                </b-col>
                <b-col cols="12">
                  <div class="schedule-container">
                    <table class="meeting-advanced">
                      <!-- Days Table head -->
                      <thead class="">
                        <th class="schedule-head">{{ $t('Day') }}</th>
                        <th>
                          <span class="from">{{ $t('From') }}</span>
                          <span class="to">{{ $t('To') }}</span>
                        </th>
                      </thead>
                      <tbody>
                        <!-- Days iteration -->
                        <tr v-for="(day, index) in days" :key="index">
                          <td
                            class="the-days"
                            :class="
                              day.name == test
                                ? 'schedule-day'
                                : 'schedule-day bordered'
                            "
                          >
                            <b-row>
                              <b-col col lg="3" md="3" sm="12" >
                                <b-form-checkbox
                                  :checked="day.checked"
                                  class="custom-control-primary"
                                  name="check-button"
                                  switch
                                  @change="changeState(index)"
                                />
                              </b-col>
                              <b-col col lg="8" md="8" sm="12">{{ day.name }}</b-col>
                            </b-row>
                          </td>
                          <div
                          class="time-start-end"
                            v-for="(time, i) in day.times"
                            :key="i"
                          >
                            <td
                              class="the-time-start"
                              :class="
                                day.name == test
                                  ? 'schedule-day'
                                  : 'schedule-day bordered'
                              "
                            >
                              <datetime
                                :disabled="day.checked == false"
                                type="time"
                                v-model="time.from"
                                v-if="day.checked == true"
                              ></datetime>
                            </td>
                            <td
                              :class="
                                day.name == test
                                  ? 'schedule-day'
                                  : 'schedule-day bordered'
                              "
                            >
                              <datetime
                                :disabled="day.checked == false"
                                type="time"
                                v-model="time.to"
                                v-if="day.checked == true"
                              ></datetime>
                            </td>
                            <td class="buttons">
                              <feather-icon
                                v-if="day.checked == true"
                                class="add-time-icon"
                                icon="PlusCircleIcon"
                                @click="Addtimeline(index)"
                              />
                              <feather-icon
                                v-if="day.checked == true && i > 0"
                                class="remove-time-icon"
                                icon="MinusCircleIcon"
                                @click="Minustimeline(index,i)"
                              />
                            </td>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <button @click="updateMeeting()" class="update-meeting-info">{{ $t('Update_meeting_information') }}</button>
            </b-card> </b-card-group
        ></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCard,
  BCardGroup,
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCardFooter,
  BBadge,
  BFormTextarea,
  BCardText,
  BFormDatepicker
} from "bootstrap-vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import axios from "axios";

export default {
  data() {
    return {
      durations: [{ title: '15 min',value:'15' }, { title: '30 min',value:'30' }, { title: '45 min',value:'45' }, { title: '60 min',value:'60' }],
      meeting: {
        user: JSON.parse(localStorage.getItem("userData"))._id,
        meetingName:"",
        description:'',
        startDate: "",
        endDate: "",
        duration: [],
        times : {}
      },
      days: [
        { id: "1", name: "Sunday", checked: false, times: [{from: "00:00",to: "00:00"}]},
        { id: "2", name: "Monday", checked: false, times: [{from: "00:00",to: "00:00"}]},
        { id: "3", name: "Tuesday", checked: false, times: [{from: "00:00",to: "00:00"}]},
        { id: "4", name: "Wednesday", checked: false, times: [{from: "00:00",to: "00:00"}]},
        { id: "5", name: "Thursday", checked: false, times: [{from: "00:00",to: "00:00"}]},
        { id: "6", name: "Friday", checked: false, times: [{from: "00:00",to: "00:00"}]},
        { id: "7", name: "Saturday", checked: false, times: [{from: "00:00",to: "00:00"}]},
      ],
      minEndDate: null,
      maxEndDate: null,
      test: "Saturday",
    };
  },
  props: {
    Meetingselected: Object,
  },
  components: {
    ToastificationContent,
    BFormTextarea,
    BCardText,
    BCardActions,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BContainer,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,
    BBadge,
    Datetime,
    BFormDatepicker
  },
  directives: {
    Ripple,
  },
  computed: {},
  created() {},
  mounted() {
    axios.get("https://backendapinodejs.timecheckit.com/api/meetings?id=" +this.$route.params.id)
      .then((response) => {
        this.meeting = response.data.data;
        const duration = this.meeting.duration.map((value) => ({
          title: `${value} min`,
          value: value,
        }));
        this.meeting.duration = duration
        this.minEndDate = this.meeting.startDate
        this.maxEndDate = this.meeting.endDate

        Object.keys(this.meeting.times).forEach(dayKey => {
          const day = this.days.find(day => day.name === dayKey)
          if (day) {
            day.checked = true;
            day.times = this.meeting.times[day.name]
          }
        })
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
  },
  watch: {
    'meeting.startDate': function (newStartDate) {
      this.minEndDate = newStartDate;
    },
    'meeting.endDate': function (newStartDate) {
      this.maxEndDate = newStartDate;
    },
  },
  methods: {
    changeState(index) {
      this.days[index].checked = !this.days[index].checked
    },
    Addtimeline(index) {
      this.days[index].times.push({
        from: "00:00",
        to: "00:00",
      });
    },
    Minustimeline(index1,index2) {
      this.days[index1].times.splice(index2,1);
    },
    updateMeeting() {
      if (Object.values(this.meeting).some((value) => value === null || value === "") || this.meeting.duration.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Complete the form to proceed",
            icon: "EditIcon",
            variant: "warning",
          },
        });      
      }
      else {
        this.meeting.duration.forEach((element, index) => {
          if (element.value) {
            this.meeting.duration[index] = element.value;
          }
        });
        const times = {};
        this.days.forEach((day) => {
          if (day.checked) {
            times[day.name] = JSON.parse(JSON.stringify(day.times));
          }
        });
        Object.keys(times).forEach((day) => {
          times[day].forEach((time) => {
            time.from = this.formatTime(time.from)
            time.to = this.formatTime(time.to)
          });
        });
        this.meeting.times = times
        axios.put(
            "https://backendapinodejs.timecheckit.com/api/meetings/"+this.$route.params.id,this.meeting)
          .then((response) => {
                this.$toast({
              component: ToastificationContent,
              props: {
                title: "Meeting updated",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.log(error)
              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error during update",
                icon: "EditIcon",
                variant: "danger",
              },
            });
            });                 
        }
    },
    formatTime(time) {
      const date = new Date(time);
      var hours = date.getUTCHours().toString().padStart(2, '0');
      let intValue = parseInt(hours, 10) + 1;
      if (intValue === 24) {
        intValue = 0;
      }
      hours = intValue.toString().padStart(hours.length, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "../../../assets/time-scroll-selector.scss";
@media screen and (max-width: 768px) {
  .mettings .b-form-datepicker label{
    font-size: 12px;
  }
  .mettings .meeting-advanced{
    width: 100%;
    table-layout: fixed;
  }
  .mettings .meeting-advanced thead{
    display: none;
  }
  .mettings .meeting-advanced .vdatetime input{
      width: 100%;
  }
  .mettings .meeting-advanced .time-start-end{
         display: grid;
  }
  
}



@media (min-width: 576px) {}

    .mettings .card .card-header {
    border-bottom: none !important;
    padding: 1rem !important;
    background: linear-gradient(
      118deg,
      #9600ff,
      rgba(115, 103, 240, 0.7)
    ) !important;
    color: white !important;
  }
  .mettings .card-body {
    padding-top: 3% !important;
  }
  .schedule-head {
    padding: 1rem;
    padding-right: 10rem;
    padding-left: 4rem;
  }
  .schedule-container {
    background: rgba(218, 218, 218, 0.233);
    border-radius: 25px;
    width: 100%;
    // max-width: fit-content;
  }
  .schedule-day {
    padding: 1.2rem;
  }
  .schedule-actions {
    padding: 7.2rem;
  }
  .schedule-actions-head {
    padding: 2rem;
    padding-right: 2rem;
  }
  .vdatetime-input {
    background-color: white;
    color: black;
    border-color: #d0cdcd24 !important;
    border-radius: 15px;
    text-align: center;
  }
  .add-time {
    width: 0.4rem;
    height: 1rem;
    float: left;
  }
  .add-time-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: rgb(0, 170, 0);
    cursor: pointer;
    margin-right: 5px;
  }
  .remove-time-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: rgb(228, 66, 66);
    cursor: pointer;
  }
  .from {
    padding-left: 6rem;
  }
  .to {
    padding-left: 14rem
  }
@media (max-width: 360px) {
  h2 {
    font-size: 15px;
  }
  .working-hours {
    padding-top: 5px
  }
  .the-days {
    display: block;
  }
  .the-time-start {
    display: block;
  }
  .schedule-container {
    background: rgba(218, 218, 218, 0.233);
    border-radius: 25px;
    width: 100%;
    // max-width: fit-content;
  }
  .from {
    visibility: hidden;
  }
  .to {
    visibility: hidden;
  }
  .schedule-head {
    padding: 1rem;
    padding-right: 124px;
    padding-left: 100px;
  }
  .buttons {
    display: block;
    text-align: center;
    padding-right: 35px;
  }
}
[dir] .vdatetime-popup__header{
  background:#9600ff
}
.vdatetime-popup__actions__button{
  color: #9600ff;
}
.vdatetime-time-picker__item--selected{
  color: #9600ff;
}
.update-meeting-info{
  margin-top:5%;
  background-color: rgb(150, 0, 255);
    border-color: rgb(150, 0, 255);
    color: white;
    padding: 0.786rem 1.5rem;
    border-radius: 0.4285rem;
    float: right;
    border: none;
}
  
</style>
