<template>
  <div class="mettings">
    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <b-card-group>
            <b-card align="left">
              <div class="meeting-container">
            <h6 class="gen-title">{{ $t('Meeting_type_name') }}</h6>
            <h4 class="gen-value">{{ meeting.meetingName }}</h4>
            <!-- <h4 class="gen-value">{{ selected.MeetingName }}</h4> -->
          </div>
          <div class="meeting-container">
            <h6 class="gen-title">{{ $t('Meeting_duration') }}</h6>
            <h4 class="gen-value">{{ meeting.duration }}</h4>
            <!-- <h4 class="gen-value">{{ selected.duration }} Min</h4> -->
          </div>
          <div class="meeting-container">
            <h6 class="gen-title">{{ $t('Start_date') }}</h6>
            <h4 class="gen-value">{{ meeting.startDate }}</h4>
          </div>
          <div class="meeting-container">
            <h6 class="gen-title">{{ $t('End_date') }}</h6>
            <h4 class="gen-value">{{ meeting.endDate }}</h4>
          </div>
          <div class="meeting-container">
            <h6 class="gen-title">{{ $t('Meeting_link') }}</h6>
            <b-button class="booking-btn-preview display-responsive-none" variant="outline-primary">
              <a :href="getlink()" target="_blank" class="button-link">{{ $t('Preview') }}</a>
            </b-button>


                <b-input-group class="booking-preprend display-none">
                  <b-form-input
                    id="task-title"
                    v-model="meeting.booking"
                    autofocus
                    trim
                    disabled
                  />
                  <b-input-group-append id="all-btns">
                    <!-- <b-button class="booking-btn-copy btn-send" variant="outline-primary">Send</b-button> -->
                    <b-button
                      class="booking-btn-copy"
                      variant="outline-primary"
                      v-clipboard:copy="meeting.booking"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      >{{ $t('Copy') }}</b-button
                    >
                    <b-button class="booking-btn-preview" variant="outline-primary">
                      <a :href="getlink()" target="_blank" class="button-link">{{ $t('Preview') }}</a>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="meeting-container">
                <h6 class="gen-title">Description</h6>
                <h4 class="gen-value description">
                  {{ meeting.description }}
                </h4>
                <!-- <h4 class="gen-value description">{{ selected.MeetingDescription }}</h4> -->
              </div>
            </b-card>
          </b-card-group></b-col
        >
      </b-row>
    </b-container>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BCard,
  BCardGroup,
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCardFooter,
  BBadge,
} from "bootstrap-vue";

export default {
  data() {
    return {
      selectedmeeting: null,
      meeting: {
        meetingName: "Loading...",
        meetingDescription: "Loading...",
        duration: "Loading...",
        booking: "Loading...",
      },
    };
  },

  components: {
    BCardActions,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BContainer,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,

    BBadge,
  },
  props: {
    Meetingselected: Object,
  },
  created() {
  },
  async mounted() {
    const response = await axios.get("https://backendapinodejs.timecheckit.com/api/meetings?id=" +this.$route.params.id)
    this.meeting = response.data.data
    var timeString = "";
    this.meeting.duration.forEach((element, index) => {
      timeString += element + " min";
      if (index < this.meeting.duration.length - 1) {
        timeString += ", ";
      }
    });
    this.meeting.duration = timeString;
    this.meeting.booking = "https://timecheck-dashboard.onrender.com/apps/meetings/preview?id=" + this.meeting._id;
  },
  methods: {
    // async displayData() {
    //   console.log("this.$props0", this.$props);
    //   this.meeting = await this.$props.Meetingselected;
    //   console.log("this.$props", this.meeting);
    //   var timeString = "";
    //   if (this.meeting.duration) {
    //     if (this.meeting.duration.length > 0) {
    //       this.meeting.duration.forEach((element) => {
    //         timeString = timeString + element + " min ";
    //       });
    //       this.meeting.duration = timeString;
    //       // this.meeting.duration=this.meeting.duration.toString()
    //     }
    //   }
    //   this.meeting.booking =
    //     "www.timecheck.com/apps/meetings/" + this.meeting._id;
    // },
    getlink() {
      return "/apps/meetings/preview?id=" + this.$route.params.id;
    },
    dateFormat: function (value) {
      return new Date(value).toLocaleString();
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Text copied successfully",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error in copying text",
          icon: "EditIcon",
          variant: "error",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
// scoped

.mettings .card-body {
  padding-top: 3% !important;
}


@media screen and (max-width: 768px) {
  .meeting-container .gen-title{
    padding-top: 5%;
  }
}


@media (min-width: 576px) {
  [dir="ltr"] .card-deck {
    margin-right: 4rem;
    margin-left: 4rem;
  }
  .meeting-container {
    height: auto;
    padding: 5%;
    display: block;
    position: relative;
    padding: 6%;
    border-bottom: solid 1px rgba(206, 190, 206, 0.527);
  }
  .gen-title {
    top: 0px;
    padding-top: 1rem;
    position: absolute;
    right: 0px;
    left: 0px;
    color: #756af0;
  }
  .link-button-group {
    position: relative !important;
    display: inline-block !important;
    padding: 0;
  }
  .booking-btn {
    border: 1px solid #672dbbe3 !important;
    background-color: transparent;
  }
  .booking-btn-copy {
    border-right: none !important;
  }

  .meeting-general {
    padding: 15px !important;
    background-color: #756af0 !important;
  }
  .gen-value {
    float: left;
  }
  #all-btns {
    padding-top: 0px;
  }
}
@media (min-width: 360px) {
  .meeting-container {
    height: auto;
    padding: 5%;
    display: block;
    position: relative;
    padding: 6%;
    border-bottom: solid 1px rgba(206, 190, 206, 0.527);
  }
  .gen-title {
    color: #756af0;
  }
  .description {
    font-size: 15px !important;
  }
  .booking-btn-copy {
    padding: 10px;
  }
  .btn-send {
    padding: 10px;
  }
  // #all-btns {
  //   padding-top: 10px;
  // }
}
</style>
