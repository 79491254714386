<template>
  <div class="container-fluid mymeetings">
    <div class="row">
      <div class="col">
        <h2 class="my-2">
          <b-badge variant="primary" class="badge-top-section">{{ $t('Active_meetings') }}</b-badge>
        </h2>
      </div>
    </div>
    <div class="row equal">
      <div v-if="meetings.length===0">
        <h4>{{ $t('There_havent_been_any_meetings_yet') }}.</h4>
      </div>
      <div
        class="col-xs-12 col-sm-6 col-md-3 d-flex pb-3"
        v-for="(meet, index) in activeMeetings"
        :key="meet.id"
      >
        <div class="card w-100 ">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h5 class="card-title flex-nowrap">{{ meet.meetingName }}</h5>
              <span @click="handleMeetingEditing(meet)"></span>
            </div>
            <h6><feather-icon icon="AlignCenterIcon"/> <b>Description</b></h6>
            <p class="card-text">{{ minimizedDescription(meet.description) }}</p>
          </div>
          <div class="card-footer bg-transparent">
            <b-button
              class="btn btn-md btn-app mr-2 mb-1"
              style="
                background-color: #9600ff!important;
                color: #fff;"
              :to="`/apps/meetings/${meet._id}`"
            >
              <feather-icon icon="EyeIcon" />
               {{ $t('View_more') }}
            </b-button>
            <b-button
              class="btn btn-md btn-danger mb-1"
              @click="setArchiveMeeting(meet,index)"
            >
              <feather-icon icon="ArchiveIcon" />
               {{ $t('Archive') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-show="this.archivedMeetings.length > 0">
      <div class="col">
        <h2 class="my-2">
          <b-badge variant="danger" class="badge-top-section"> {{ $t('Archived_meetings') }}</b-badge>
        </h2>
      </div>
    </div>
    <div class="row equal" v-show="this.archivedMeetings.length > 0">
      <div
        class="col-xs-12 col-sm-6 col-md-3 d-flex pb-3"
        v-for="(meet, index) in archivedMeetings"
        :key="meet.id"
      >
        <div class="card w-100">
          <div class="card-body">
            <h5 class="card-title flex-nowrap">{{ meet.meetingName }}</h5>
            <h6><feather-icon icon="AlignCenterIcon"/> <b>Description</b></h6>
            <p class="card-text">{{ minimizedDescription(meet.description) }}</p>
          </div>
          <div class="card-footer bg-transparent text-center">
            <b-button
              class="btn btn-md btn-success mr-2 mb-1"
              @click="restoreMeeting(meet)"
            >
               {{ $t('Restore') }}
            </b-button>
            <b-button
              class="btn btn-md btn-danger mb-1"
              @click="deleteMeeting(meet._id,index)"
            >
              <feather-icon icon="TrashIcon" />
               {{ $t('Delete') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { mapGetters, mapActions } from "vuex";
import { Bus } from "@/utils/bus";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BCard,
  BCardGroup,
  BButton,
  BContainer,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormCheckbox,
  BCardFooter,
  BBadge,
} from "bootstrap-vue";
export default {
  data() {
    return {
      meetings : [],
      activeMeetings : [],
      archivedMeetings: [],
      deletedMeetings : []
    };
  },
  components: {
    BCardActions,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BCardGroup,
    BRow,
    BCol,
    BContainer,
    BFormCheckboxGroup,
    BFormCheckbox,
    BBadge,
  },
  beforeCreate() {
    // this.$store.dispatch("fetchmeetings");
  },
  computed: {
  },
  created(){
    this.getMettings()
  },
  methods: {
    getMettings() {
      axios.get("https://backendapinodejs.timecheckit.com/api/meetings?user=" +JSON.parse(localStorage.getItem("userData"))._id)
      .then((response) => {
        this.meetings = response.data.data
        this.meetings.forEach(meet => {
          if (meet.status === "active") {
            this.activeMeetings.push(meet)
          }
          else {
            this.archivedMeetings.push(meet)
          }
        })
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
    },
    minimizedDescription(description) {
      const maxLength = 60;
      if (description) {
        if (description.length <= maxLength) {
          return description;
        } else {
          return description.slice(0, maxLength) + "...";
        }
      }
    },
    setArchiveMeeting(meet,index) {
      const id = meet._id
      axios.put(`https://backendapinodejs.timecheckit.com/api/meetings/${id}`, { status : "archived" })
      .then((response) => {
        this.archivedMeetings.push(meet)
        this.activeMeetings.splice(index,1)
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
    },
    restoreMeeting(meet,index) {
      const id = meet._id
      axios.put(`https://backendapinodejs.timecheckit.com/api/meetings/${id}`, { status : "active" })
      .then((response) => {
        this.archivedMeetings.splice(index,1)
        this.activeMeetings.push(meet)
      })
      .catch((error) => {
        this.errorMessage = error.message;
      });
    },
    deleteMeeting(id,index) {
      this.$swal({
        title: "Are you sure you want to delete this meeting permanently?",
        text: "You can't undo this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm !",
        cancelButtonText: "Decline !",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.delete(`https://backendapinodejs.timecheckit.com/api/meetings/${id}`)
          .then((response) => {
            this.archivedMeetings.splice(index,1)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Meeting deleted`,
                icon: "UserIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error while deleting`,
                  icon: "UserIcon",
                  variant: "danger",
                },
            });
          });
        }
      });
    },
  },
};
</script>

<style>
.cards-space-tb {
  margin-top: 3%;
  margin-bottom: 3%;
}
.board-item {
  background-color: #fff !important;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
.mymeetings {
  padding-right: 0rem !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

</style>
